<template>
<div class="background">  
  <!-- 头部 -->
  <div class="head">XXXX云平台</div>
  <!-- 左侧导航栏 -->
  <el-row class="tac">
    <el-col :span="24">
      <el-menu
        :default-active="activeIndex"
        router
        mode="vertical"
        background-color="#1234a380"
        text-color="#9b9999"
        active-text-color="#fff"       
        @select="handleSelect">
        <el-menu-item v-for="(item,i) in navList" :key="i" :index="item.name">
          <i :class="item.iconCls"></i>
          <span>{{ item.navItem }}</span>
        </el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
  <!-- krpano全景图 -->
  <div id="pano" ></div>  
  <!-- 底部 -->
  <div class="bottom">
    <!-- 底部左侧 -->
    <div class="bottom-l">
      <el-button class="prev" @click="prev" type="primary" icon="iconfont icon-youqianjin"></el-button>
      <!-- <el-button @click="thumbs" type="primary" icon="iconfont icon-yingyongzhongxin"></el-button> -->
    </div>
    <!-- 底部中间 -->
    <div class="bottom-m">
      <div class="win">
        <el-button @mousedown.native="lrotatedown" @mouseup.native="lrotateup" @mouseout.native="lrotateup" type="primary" icon="iconfont icon-xiangzuo"></el-button>
        <el-button @mousedown.native="rrotatedown" @mouseup.native="rrotateup" @mouseout.native="rrotateup" type="primary" icon="iconfont icon-xiangyou"></el-button>
        <el-button @mousedown.native="urotatedown" @mouseup.native="urotateup" @mouseout.native="urotateup" type="primary" icon="iconfont icon-xiangshang"></el-button>
        <el-button @mousedown.native="drotatedown" @mouseup.native="drotateup" @mouseout.native="drotateup" type="primary" icon="iconfont icon-paixu"></el-button>
        <el-button @mousedown.native="zoomindown" @mouseup.native="zoominup" @mouseout.native="zoominup" type="primary" icon="iconfont icon-jiajianzujianjiahao"></el-button>
        <el-button @mousedown.native="zoomoutdown" @mouseup.native="zoomoutup" @mouseout.native="zoomoutup" type="primary" icon="iconfont icon-jian"></el-button>
      </div>
      <div class="mobile">
        <el-button @click="zoomindownm" type="primary" icon="iconfont icon-jiajianzujianjiahao"></el-button>
        <el-button @click="zoomoutdownm" type="primary" icon="iconfont icon-jian"></el-button>
      </div>
    </div>
    <!-- 底部右侧 -->
    <div class="bottom-r">
      <el-button class="next" @click="next" type="primary" icon="iconfont icon-zuoqianjin"></el-button>
    </div>
  </div>
  <div class="main">
    <router-view></router-view>
  </div>
</div> 
</template>

<script>
// import axios from 'axios'

export default {
  name: 'PlateForm',
  props: ['id'],
  data() {
    return {
      index: 1,
      activeIndex: '/spzx',
      navList: [
        { name: '/cqcm', navItem: '村情村貌', iconCls: 'el-icon-menu' },
        { name: '/dyfb', navItem: '党员分布', iconCls: 'el-icon-menu' },
        { name: '/xcjj', navItem: '乡村经济', iconCls: 'el-icon-menu' },
        { name: '/wgtx', navItem: '网格体系', iconCls: 'el-icon-menu' },
        { name: '/tdgl', navItem: '土地管理', iconCls: 'el-icon-menu' },
        { name: '/szrh', navItem: '三治融合', iconCls: 'el-icon-menu' },
        { name: '/spzx', navItem: '视频中心', iconCls: 'el-icon-menu' }
      ]      
    }
  },
  mounted(){
    // this.activeIndex = this.$route.matched[1].path
    embedpano({swf:"./vtour/tour.swf", xml:"./vtour/tour.xml", target:"pano", html5:"only", mobilescale:1.0, passQueryParameters:true})
  },
  created() {
    // axios.get(`/api/samples/${this.id}?populate=*`)
    //   .then((resp) => {
    //     var krpano = document.querySelector('#krpanoSWFObject')
    //     var imgs = resp.data.data.attributes.img.data
    //     imgs.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name))
    //     var title = resp.data.data.attributes.name 
    //     var description = resp.data.data.attributes.description
    //     var imgTotal = imgs.length
    //     var flag = 0
    //     for(var i = 0; i <imgTotal; i++) {
    //       this.img[i] = new Image()
    //       this.img[i].src = axios.defaults.baseURL + imgs[i].attributes.url
    //       // 判断图片加载状况，加载完成后回调
    //       // 加载完成
    //       flag++;
    //       //console.log(flag);
    //       var fname = 'frame'+i
    //       krpano.call('addplugin('+fname+');'+'plugin['+fname+'].loadstyle(frame);'+'set(plugin['+fname+'].url,'+this.img[i].src+')')
    //       krpano.call("set(currentframe,0);set(framecount,"+imgTotal+");set(oldmousex,0);showframe(0)")                 
    //     }//end for循环
        
    //     //介绍文字按钮  zcp  放大缩小在xml里面
    //     krpano.call('set(layer[infotext].html,'+'[h1]'+title+'[/h1][br][br][div]'+description+'[/div])')   
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //   })
  },  
  methods:{
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
    },
    // 全景操作
    // 左旋转
    lrotateup() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("hlookat_moveforce",0)
    },
    lrotatedown() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("hlookat_moveforce",-1)
    },
    // 右旋转
    rrotateup() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("hlookat_moveforce",0)
    },
    rrotatedown() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("hlookat_moveforce",+1)
    },
    // 上倾斜
    urotateup() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("vlookat_moveforce",0)
    },
    urotatedown() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("vlookat_moveforce",-1)
    },
    // 下倾斜
    drotateup() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("vlookat_moveforce",0)
    },
    drotatedown() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("vlookat_moveforce",+1)
    },
    // 放大
    zoominup() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("fov_moveforce",0)
    },
    zoomindown() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("fov_moveforce",-1)
    },
    zoomindownm() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("fov_moveforce",-1)
      setTimeout(this.zoominup, 200)
    },
    // 缩小
    zoomoutup() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("fov_moveforce",0)
    },
    zoomoutdown() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("fov_moveforce",+1)
    },
    zoomoutdownm() {
      var krpano = document.getElementById('krpanoSWFObject')
      krpano.set("fov_moveforce",+1)
      setTimeout(this.zoomoutup, 200)
    },
    // 上一张
    prev() {
      var krpano = document.getElementById('krpanoSWFObject')    
      if(this.index > 1) {
        this.index -=1
        var tonext = "to" + this.index
        krpano.call(tonext)
      }
    },
    // 下一张
    next() {
      var krpano = document.getElementById('krpanoSWFObject')
      if(this.index < 2) {
        this.index +=1
        var tonext = "to" + this.index
        krpano.call(tonext)
      }     
    },
    // 选择全景图
    thumbs() {

    }
  }
}
</script>


<style lang="less" scoped>
.background {
  width: 100%;
  height: 100%;
  position: absolute;
  .head {
    width: 100%;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background-color: #0105125e;
    color: #fff;
    font-size: 2em;
    position: absolute;
    z-index: 999;
  }
  .tac {
    position: absolute;
    top: 80px;
    left: 0;
    width: 60px;
    height: calc(100vh - 140px);
    background-color: #01040e66;
    z-index: 999;
    .el-col {
      .el-menu {
        width: 100%;
        border-right: 0;
        background-color: #fdfdfd00 !important;
        .el-menu-item {
          padding: 0 !important;
          width: 100%;
          height: 60px;
          background-color: #fdfdfd00 !important;
          padding-top: 10%;
          i {
            display: block;
            width: 100%;
            height: 60%;
            font-size: 1.8em;
          }
          span {
            display: block;
            width: 100%;
            height: 40%;
            line-height: 0;
            font-size: 0.8em;
            text-align: center;
          }
        }
      } 
    }
  } 
  .bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    background-color: #040d2a5e;
    color: #fff;
    line-height: 60px;
    position: absolute;
    bottom: 0;
    z-index: 999;
    .el-button {
      background-color: #2125266b;
      border-color: #b6bcc370;
    }
    .bottom-l {
      padding-left: 10px;
    }
    .bottom-m {
      .mobile {
        display: none;
      }
    }
    .bottom-r {
      padding-right: 10px;
    }
  }  
  #pano{
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 960px){
  .background {
    .head {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background-color: #0105125e;
      color: #fff;
      font-size: 1em;
      position: absolute;
      z-index: 999;
    }
    .tac {
      position: absolute;
      top: 50px;
      left: 0;
      width: 60px;
      height: calc(100vh - 110px);
      background-color: #01040e66;
      z-index: 999;
      .el-col {
        .el-menu {
          width: 100%;
          border-right: 0;
          background-color: #fdfdfd00 !important;
          .el-menu-item {
            padding: 0 !important;
            width: 100%;
            height: 60px;
            background-color: #fdfdfd00 !important;
            padding-top: 10%;
            i {
              display: block;
              width: 100%;
              height: 60%;
              font-size: 1.8em;
            }
            span {
              display: block;
              width: 100%;
              height: 40%;
              line-height: 0;
              font-size: 0.8em;
              text-align: center;
            }
          }
        } 
      }
    }  
    .bottom {
      width: 100%;
      height: 60px;
      background-color: #0105125e;
      color: #fff;
      line-height: 60px;
      position: absolute;
      bottom: 0;
      z-index: 999;
      .bottom-m {
        .win {
          display: none;
        }
        .mobile {
          display: block;
        }   
      }
      .el-button {
        background-color: #2125266b;
        border-color: #b6bcc370;
      }
    }  
    #pano{
      width: 100%;
      height: 100%;
    }
  }
}
</style>

