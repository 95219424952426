<template> 
  <el-row>
    <!-- 视频流 -->
    <el-col class="box1" :lg="{span: 7}" :xs="{span:18}">
      <div class="box-h">视频流</div>
      <div class="box-b">
        <img src="../../public/img/nsl.jpg" alt="">
        <img src="../../public/img/nsl.jpg" alt="">
        <img src="../../public/img/nsl.jpg" alt="">
        <img src="../../public/img/nsl.jpg" alt="">
        <img src="../../public/img/nsl.jpg" alt="">
        <img src="../../public/img/nsl.jpg" alt="">
        <img src="../../public/img/nsl.jpg" alt="">
        <img src="../../public/img/nsl.jpg" alt="">
      </div>
    </el-col>
    <!-- 视频列表 -->
    <el-col class="box2" :lg="{span: 7}" :xs="{span:18}">
      <div class="box-h">视频列表</div>
      <div class="box-m">
        <el-table
          :data="tableData"
          style="width: 100%">
          <el-table-column
            prop="state"
            label="状态"
            width="60">
          </el-table-column>
          <el-table-column
            prop="address"
            label="地址">
          </el-table-column>
          <el-table-column
            prop="date"
            label="日期"
            width="100">
          </el-table-column>        
        </el-table>      
      </div>
      <div class="box-b_h">
        异常事件列表
      </div>
      <div class="box-b_b">
        <el-table
          :data="tableData2"
          style="width: 100%">
          <el-table-column
            prop="state"
            label="状态"
            width="60">
          </el-table-column>
          <el-table-column
            prop="address"
            label="地址">
          </el-table-column>
          <el-table-column
            prop="date"
            label="日期">
          </el-table-column>
          <el-table-column
            label="操作"
            width="100">
          <template slot-scope="scope">
              <el-button size="mini"
                @click="album(scope.row.id)">立即处理</el-button>        
          </template>
          </el-table-column>
          <!-- <el-table-column
            label="封面"
            width="100%">
            <template slot-scope="img">
              <img :src="img.row.images" alt="">
            </template>
          </el-table-column>            -->
        </el-table>
        <img style="width: 100%;" src="../../public/img/nsl.jpg" alt="">       
      </div>     
    </el-col>
    <el-button class="spl" icon="el-icon-refresh" @click="change1">视频流</el-button>
    <el-button class="splb" icon="el-icon-refresh" @click="change2">视频列表</el-button>
  </el-row>  
</template>

<script>
export default {
  data() {
    return {
      tableData: [{
        state: '正常',
        address: '党群服务中心',
        date: '2022-02-02'
      }, {
        state: '正常',
        address: '文化广场',
        date: '2022-02-02'
      }, {
        state: '正常',
        address: '村东头',
        date: '2022-02-02'
      },{
        state: '正常',
        address: '党群服务中心',
        date: '2022-02-02'
      }, {
        state: '正常',
        address: '文化广场',
        date: '2022-02-02'
      }, {
        state: '正常',
        address: '村东头',
        date: '2022-02-02'
      }, {
        state: '正常',
        address: '党群服务中心',
        date: '2022-02-02'
      }, {
        state: '正常',
        address: '文化广场',
        date: '2022-02-02'
      }, {
        state: '正常',
        address: '村东头',
        date: '2022-02-02'
      }, {
        state: '正常',
        address: '党群服务中心',
        date: '2022-02-02'
      }, {
        state: '正常',
        address: '文化广场',
        date: '2022-02-02'
      }, {
        state: '正常',
        address: '村东头',
        date: '2022-02-02'
      },  
      {
        state: '正常',
        address: '村西头',
        date: '2022-02-02'
      }],
      tableData2: [{
        state: '异常',
        address: '村西头',
        date: '2022-02-02'
      }]
    }
  },
  methods: {
    change1() {
      var spl = document.querySelector(".spl")
      var splb = document.querySelector(".splb")
      var box1 = document.querySelector(".box1")
      var box2 = document.querySelector(".box2")
      spl.style.display = "none"
      splb.style.display = "block"
      box1.style.display = "block"
      box2.style.display = "none"
    },
    change2() {
      var spl = document.querySelector(".spl")
      var splb = document.querySelector(".splb")
      var box1 = document.querySelector(".box1")
      var box2 = document.querySelector(".box2")
      spl.style.display = "block"
      splb.style.display = "none"
      box1.style.display = "none"
      box2.style.display = "block"
    }
  }
}
</script>

<style lang="less" scoped>
  .spl {
    display: none;
  }
  .splb {
    display: none;
  }
  .box1 {
    position: fixed;
    top: 120px;
    right: 120px;
    background-color:#0105125e;
    z-index: 999;
    .box-h {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 1.5em;
      color: #fff;
      border-bottom: 1px #fff solid;
    }
    .box-b {
      padding: 10px 0 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      img {
        margin-top: 15px;
        width: 45%;
      }
      img:nth-child(odd) {
        margin-right: 15px;
      }
    }     
  }
  .box2 {
    position: fixed;
    top: 10%;
    left: 5%;
    background-color:#0105125e;
    z-index: 999;
    .box-h {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 1.5em;
      color: #fff;
      border-bottom: 1px #fff solid;
    }
    .box-m {
      width: 100%;
      height: 340px;
      .el-table {
        font-size: 0.9em;
        table-layout: fixed;
        background-color:#0105125e !important;
        color: #fff;
        max-height: 340px;
        /deep/ td {
          border-bottom: #0105125e !important;
        }
        /deep/ th {
          background-color:#0105125e !important;
          /deep/ .is-leaf {
            border-bottom: #0105125e !important;
          }
        }
        /deep/ tr {
          background-color:#ffffff00 !important;
        }
        /deep/ .el-table__header-wrapper {
          display: none;
        }
      }

    }
    .box-b_h {
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding-left: 20px;
      font-size: 16px;
      font-weight: bold;
      color: red;    
    }
    .box-b_b {
      padding: 0 20px 20px;
      .el-table::before {
        background-color: #ebeef500;
      }
      .el-table {
        font-size: 0.9em;
        background-color:#32458300 !important;
        color: #fff;
        max-height: 380px;
        .el-button {
          background-color: #00a6ff;
          color: #fff;
          border: 0;
        }
        /deep/ td {
          border-bottom: #0105125e !important;
        }
        /deep/ th {
          background-color:#0105125e !important;
          /deep/ .is-leaf {
            border-bottom: #0105125e !important;
          }
        }
        /deep/ tr {
          background-color:#ffffff12 !important;
        }
        /deep/ .el-table__header-wrapper {
          display: none;
        }
      }      
    }
  }
  @media screen and (max-width: 960px){
  .spl {
    display: block;
    padding: 8px 16px;
    position: fixed;
    top: 60px;
    left: 70px;
    z-index: 999;
    color: #fff;
    background-color: #2125266b;
    border-color: #b6bcc370;
  }
  .splb {
    display: none;
    padding: 8px 16px;
    position: fixed;
    top: 60px;
    left: 60px;
    z-index: 999;
    color: #fff;
    background-color: #2125266b;
    border-color: #b6bcc370;
  }  
  .box1 {
    display: none;
    position: fixed;
    top: 100px;
    left: 70px;
    background-color:#0105125e;
    z-index: 999;
    .box-h {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 1em;
      color: #fff;
      border-bottom: 1px #fff solid;
    }
    .box-b {
      padding: 5px 0 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      img {
        margin-top: 10px;
        width: 45%;
      }
      img:nth-child(odd) {
        margin-right: 10px;
      }
    }     
  }
  .box2 {
    display: block;
    position: fixed;
    top: 100px;
    left: 70px;
    background-color:#0105125e;
    z-index: 999;
    .box-h {
      width: 100%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 1em;
      color: #fff;
      border-bottom: 1px #fff solid;
    }
    .box-m {
      width: 100%;
      height: 210px;
      .el-table {
        font-size: 0.9em;
        table-layout: fixed;
        background-color:#0105125e !important;
        color: #fff;
        max-height: 210px;
        /deep/ td {
          height: 30px !important;
          border-bottom: #0105125e !important;
        }
        /deep/ th {
          height: 30px !important;
          background-color:#0105125e !important;
          /deep/ .is-leaf {
            border-bottom: #0105125e !important;
          }
        }
        /deep/ tr {
          height: 30px !important;
          background-color:#ffffff00 !important;
        }
        /deep/ .el-table__header-wrapper {
          display: none;
        }
        /deep/ .el-table__cell {
          padding: 0;
        }        
      }

    }
    .box-b_h {
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding-left: 20px;
      font-size: 16px;
      font-weight: bold;
      color: red;    
    }
    .box-b_b {
      padding: 0 0 10px;
      .el-table::before {
        background-color: #ebeef500;
      }
      .el-table {
        font-size: 0.9em;
        background-color:#32458300 !important;
        color: #fff;
        max-height: 210px;
        .el-button {
          margin-left: -10px;
          padding: 3px 5px;
          background-color: #00a6ff;
          color: #fff;
          font-size: 0.8em;
          border: 0;
        }
        /deep/ td {
          height: 30px !important;
          border-bottom: #0105125e !important;
        }
        /deep/ th {
          height: 30px !important;
          background-color:#0105125e !important;
          /deep/ .is-leaf {
            border-bottom: #0105125e !important;
          }
        }
        /deep/ tr {
          height: 30px !important;
          background-color:#ffffff12 !important;
        }
        /deep/ .el-table__header-wrapper {
          display: none;
        }
        /deep/ .el-table__cell {
          font-size: 0.8em;
          padding: 0;
        }         
      }
      img {
        width: 90% !important;
        margin-left: 13px;
      }     
    }
  }
  }  
</style>