import Vue from 'vue'
import VueRouter from 'vue-router'
//import pathArr from '@/router/pathArr.js'

// 导入需要的组件
// import Login from '@/components/MyLogin.vue'
// import Home from '@/components/MyHome.vue'

import PlateForm from '../../public/PlateForm.vue'
import Cqcm from '@/components/Cqcm.vue'
import Dyfb from '@/components/Dyfb.vue'
import Xcjj from '@/components/Xcjj.vue'
import Wgtx from '@/components/Wgtx.vue'
import Tdgl from '@/components/Tdgl.vue'
import Szrh from '@/components/Szrh.vue'
import Spzx from '@/components/Spzx.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/plateform' },
    // 登录的路由规则
    // { path: '/login', component: Login},
    // // 后台主页的路由规则
    // {
    //    path: '/home', 
    //    component: Home, 
    //    redirect: '/home/samples'
    // },
    { path: '/plateform',
      redirect: '/spzx',
      component: PlateForm, 
      props: true,
      children: [
        { path: '/cqcm', component: Cqcm },
        { path: '/dyfb', component: Dyfb },
        { path: '/xcjj', component: Xcjj },
        { path: '/wgtx', component: Wgtx },
        { path: '/tdgl', component: Tdgl },
        { path: '/szrh', component: Szrh },
        { path: '/spzx', component: Spzx }
      ] 
    }
  ]
})

// 全局前置守卫
// router.beforeEach(function(to, from, next) { 
//   if(pathArr.indexOf(to.path) !== -1) {
//     const token = localStorage.getItem('token')
//     if(token) {
//       next()
//     } else {
//       next('/login')
//     }
//   } else {
//     next()
//   }
// })

export default router