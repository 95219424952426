<template>
  <div class="app-container">
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'MyApp',
  components: {}
}
</script>

<style lang="less" scoped>
</style>
