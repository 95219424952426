import Vue from 'vue'
import ElementUI from 'element-ui'
import App from './App.vue'
// 导入路由模块
import router from '@/router'
import axios from 'axios'

// 导入样式
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import '@/assets/iconfont/iconfont.css'

Vue.prototype.axios = axios
axios.defaults.baseURL = '/api'
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
